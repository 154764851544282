import type { Button } from '@repo/ui/components/Button.js'
import { cn } from '@repo/ui/lib/utils'
import type { ComponentProps } from 'react'
import type { z } from 'zod'
import { useForm } from '~/hooks/useForm'
import { LoginSchema } from '~/routes/_auth.login/route.config'
import { Form } from './form/Form'

export const LoginButton = ({
  className,
  provider,
  ...props
}: ComponentProps<typeof Button> & {
  provider: z.infer<typeof LoginSchema>['provider']
}) => {
  const [form, fields] = useForm({
    schema: LoginSchema,
    defaultValue: {
      provider,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    fetcherKey: `${LoginSchema.shape.intent.value}_${provider}`,
  })

  return (
    <Form form={form} action="/login">
      <Form.HiddenInput name={fields.provider.name} />
      <Form.HiddenInput name={fields.timezone.name} />
      <Form.SubmitButton
        icon={provider === 'google' ? 'google-color' : 'microsoft-color'}
        variant="secondary"
        className={cn(
          'h-10 w-full border-transparent',
          {
            'bg-slate-900 text-white hover:border-slate-800 hover:bg-slate-800':
              provider === 'google',
            'bg-slate-200 text-slate-800 hover:bg-slate-300 hover:text-slate-900':
              provider === 'microsoft',
          },
          className
        )}
        {...props}
      >
        <span>
          Sign in with <span className="capitalize">{provider}</span>
        </span>
      </Form.SubmitButton>
    </Form>
  )
}
